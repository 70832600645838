import React from "react";
import { Box, Divider, Fab, IconButton, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./Footer.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import Logo from "./assets/logo.png";

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: { xs: 3, md: 8 },
        px: { xs: 3, md: 20 },
        pb: { xs: 3, md: 8 },
        borderTop: "1px solid #fbdfa4",
        backgroundColor: "#000",
      }}
    >
      <Box
        sx={{
          mb: 5,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
        }}
      >
        <img src={Logo} alt="Logo" style={{ height: "50px" }} />
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            color: "#FFF",
            m: 0,
            fontWeight: 700,
            mr: 4,
            fontSize: { xs: "1.25rem", md: "1.5rem" },
          }}
        >
          Vrushadri Builders And Developers
        </Typography>

        <Box sx={{ my: 0 }}>
          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              backgroundColor: "#006983",
              borderRadius: "15px",
              boxShadow: "5px 5px 0px 0px #EF9780",
              mr: 2,
              p: 1,
              mt: { xs: 2, sm: 0 },
              "&:hover": {
                backgroundColor: "#006983",
              },
            }}
            href="https://www.facebook.com"
            target="_blank"
          >
            <FacebookIcon fontSize="inherit" sx={{ color: "#fff" }} />
          </IconButton>

          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              backgroundColor: "#006983",
              borderRadius: "15px",
              boxShadow: "5px 5px 0px 0px #FDB2C2",
              mr: 2,
              p: 1,
              mt: { xs: 2, sm: 0 },
              "&:hover": {
                backgroundColor: "#006983",
              },
            }}
            href="https://www.instagram.com"
            target="_blank"
          >
            <InstagramIcon fontSize="inherit" sx={{ color: "#fff" }} />
          </IconButton>

          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              backgroundColor: "#006983",
              borderRadius: "15px",
              boxShadow: "5px 5px 0px 0px #D6E3C5",
              mr: 2,
              p: 1,
              mt: { xs: 2, sm: 0 },
              "&:hover": {
                backgroundColor: "#006983",
              },
            }}
            href="https://www.youtube.com"
            target="_blank"
          >
            <YouTubeIcon fontSize="inherit" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 2, mb: { xs: 5, md: 0 }, mr: { xs: 0, sm: 6 } }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "#FFF",
              mb: 3,
              fontWeight: 700,
              fontSize: { xs: "1.25rem", md: "1.5rem" },
            }}
          >
            Contact Information
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#FFF",
              fontWeight: 500,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            No. 8/ 2, near SLV bakery, 4th cross, 1st floor @ bidare agrahara,
            kanmangala post, Bangalore - 560067
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#FFF",
              fontWeight: 500,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            Info: Mon - Sun 9.00 AM - 9.00 PM
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#FFF",
              fontWeight: 500,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            Tel: +91 9986224951 <Divider /> +91 9880165757
          </Typography>
        </Box>

        <Box sx={{ flex: 2, ml: { xs: 0, md: 6 }, mb: { xs: 5, md: 0 } }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "#FFF",
              mb: 3,
              fontWeight: 700,
              fontSize: { xs: "1.25rem", md: "1.5rem" },
            }}
          >
            Useful links
          </Typography>

          <Typography variant="h6" gutterBottom>
            <Typography
              variant="h6"
              gutterBottom
              component="a"
              href="/skills"
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: { xs: "1rem", md: "1.25rem", textDecoration: "none" },
              }}
            >
              Apartments
            </Typography>
          </Typography>

          <Typography variant="h6" gutterBottom>
            <Typography
              variant="h6"
              gutterBottom
              component="a"
              href="/events"
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: { xs: "1rem", md: "1.25rem", textDecoration: "none" },
              }}
            >
              Villas
            </Typography>
          </Typography>

          <Typography variant="h6" gutterBottom>
            <Typography
              variant="h6"
              gutterBottom
              component="a"
              href="/skills"
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: { xs: "1rem", md: "1.25rem", textDecoration: "none" },
              }}
            >
              Plots
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ flex: 1, mb: { xs: 5, md: 0 } }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "#FFF",
              mb: 3,
              fontWeight: 700,
              fontSize: { xs: "1.25rem", md: "1.5rem", textDecoration: "none" },
            }}
          >
            Useful links
          </Typography>

          <Typography variant="h6" gutterBottom>
            <Typography
              variant="h6"
              gutterBottom
              component="a"
              href="/contact-us"
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: { xs: "1rem", md: "1.25rem", textDecoration: "none" },
              }}
            >
              Contact us
            </Typography>
          </Typography>

          <Typography variant="h6" gutterBottom>
            <Typography
              variant="h6"
              gutterBottom
              component="a"
              href="/about-us"
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: { xs: "1rem", md: "1.25rem", textDecoration: "none" },
              }}
            >
              About us
            </Typography>
          </Typography>
        </Box>
      </Box>

      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "5%",
          left: "2%",
          backgroundColor: "#0dc043",
          "&:hover": {
            backgroundColor: "#0dc043",
          },
        }}
        href="https://wa.me/9880165757"
      >
        <WhatsAppIcon />
      </Fab>

      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "5%",
          right: "2%",
          backgroundColor: "#267bc0",
          "&:hover": {
            backgroundColor: "#267bc0",
          },
        }}
        href="tel:9986224951"
      >
        <CallIcon />
      </Fab>
    </Box>
  );
}
