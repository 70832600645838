import React from "react";
import "./App.css";
import ResponsiveAppBar from "./ResponsiveAppBar";
import HomeBanner1 from "./assets/homepage1.jpg";
import Apartments from "./assets/apartments.jpg";
import Villas from "./assets/villas.jpg";
import AboutUsImg from "./assets/aboutus.jpg";
import Plots from "./assets/plots.jpg";
import Footer from "./Footer";
import { Box, Typography } from "@mui/material";

function App() {
  return (
    <>
      <ResponsiveAppBar />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundSize: { xs: "400% 100%", md: "100%" },
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundImage: `url(${HomeBanner1})`,
            minHeight: "450px",
            borderBottom: "4rem solid black",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              opacity: 0.65,
              minHeight: "450px",
              position: "absolute",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "25%",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: "#FFF",
                  fontSize: { xs: "1.25rem", md: "2.125rem" },
                }}
              >
                Crafting Dreams into Reality: Our Vision at Vrushadri Builders &
                Developers
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: "#FFF",
                  mx: 5,
                  mt: 5,
                  mb: 10,
                  fontWeight: "lighter",
                  fontSize: { xs: "0.85rem", md: "1.25rem" },
                }}
              >
                Welcome to Vrushadri Builders & Developers, where passion meets
                commitment. We redefine urban living with excellence and
                innovation.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundSize: { xs: "400% 100%", md: "100%" },
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundImage: `url(${AboutUsImg})`,
            minHeight: "450px",
            position: "relative",
            borderBottom: "4rem solid black",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              opacity: 0.65,
              minHeight: "450px",
              position: "absolute",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                mb: 2,
                mt: { xs: "35%", md: 20 },
                fontSize: { xs: "1.5rem", md: "2rem" },
                px: "10%",
              }}
            >
              About Us
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                px: "10%",
                fontSize: { xs: "0.85rem", md: "1.25rem" },
              }}
            >
              At Vrushadri Builders & Developers, we're more than a real estate
              company - we're creators of vibrant communities. With a focus on
              excellence, innovation, and sustainability, we craft exceptional
              residential and commercial spaces that inspire connection and
              enhance lifestyles. Join us as we shape the future of real estate,
              one visionary project at a time. Welcome to Vrushadri Builders &
              Developers.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundSize: { xs: "400% 100%", md: "100%" },
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundImage: `url(${Apartments})`,
            minHeight: "450px",
            position: "relative",
            borderBottom: "4rem solid black",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              opacity: 0.65,
              minHeight: "450px",
              position: "absolute",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                mb: 2,
                mt: { xs: "35%", md: 20 },
                fontSize: { xs: "1.5rem", md: "2rem" },
                px: "10%",
              }}
            >
              Introducing Vrushadri Apartments
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                px: "10%",
                fontSize: { xs: "0.85rem", md: "1.25rem" },
              }}
            >
              Where luxury meets lifestyle. Our meticulously designed residences
              offer the perfect blend of elegance and comfort. With premium
              amenities, thoughtful layouts, and unbeatable locations, every
              Vrushadri apartment is a testament to quality and sophistication.
              Experience urban living at its finest - welcome home to Vrushadri
              Apartments.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundSize: { xs: "400% 100%", md: "100%" },
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundImage: `url(${Villas})`,
            minHeight: "450px",
            position: "relative",
            borderBottom: "4rem solid black",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              opacity: 0.65,
              minHeight: "450px",
              position: "absolute",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                mb: 2,
                mt: { xs: "50%", md: 20 },
                fontSize: { xs: "1.5rem", md: "2rem" },
                px: "10%",
              }}
            >
              Introducing Vrushadri Villas
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                px: "10%",
                fontSize: { xs: "0.85rem", md: "1.25rem" },
              }}
            >
              Luxurious living in tranquil surroundings. With spacious layouts
              and modern amenities, our villas offer the perfect blend of
              comfort and style. Experience refined living at its best - welcome
              home to Vrushadri Villas.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundSize: { xs: "400% 100%", md: "100%" },
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundImage: `url(${Plots})`,
            minHeight: "450px",
            position: "relative",
            borderBottom: "4rem solid black",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              opacity: 0.65,
              minHeight: "450px",
              position: "absolute",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                mb: 2,
                mt: { xs: "50%", md: 20 },
                fontSize: { xs: "1.5rem", md: "2rem" },
                px: "10%",
              }}
            >
              Introducing Vrushadri Plots
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#FFF",
                px: "10%",
                fontSize: { xs: "0.85rem", md: "1.25rem" },
              }}
            >
              Your canvas for a dream home. With prime locations and scenic
              views, our plots offer endless potential. Welcome to Vrushadri
              Plots, where your vision comes to life.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default App;
